@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,500;0,600;0,700;1,700&family=Zen+Tokyo+Zoo&display=swap');
*{
  margin: 0;
  padding: 0;
}
html{
  box-sizing: border-box;
}
img .img-fluid{
 position: relative;
}
.domainName{
    margin-top: -5px;
    margin-left: -10px;
    font-size: 25px;
    font-family: 'Zen Tokyo Zoo', cursive;
    font-weight: bold;
    position: absolute;

}
body {
  background:  #5D3F6A;
  color: #fff;
}
.carousel-item {
  height: 450px;
 }

.card-image{
  background-image: url("./components/img/home-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
.card{
  background-color: #6C7A89;
}
.card-body{
  color: white;
}
.card-body p{
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 18px !important;
}
.btn-home{
  overflow: hidden;
}
.btn-home a{
  color: #fff !important;
  font-weight: bold;
}
.card{
  background: rgb(93, 63, 106, 0.9);
}
.btn-link{
  text-decoration: none;
}
.explorer-section{
  background-color: rgb(93, 63, 106, 0.6);
  clip-path: polygon(0 100%, 50% 0, 100% 100%, 0 100%);
}

/* COUNTER SECTION */
.explorer-section a h2:hover{
  color: #fff !important;
  transition: all ease .6s;
  padding-top: 10px;
}
.counter-section i{
  background-color: #5D3F6A;
  padding: 15px;
  border-radius: 50%;
  margin-top: 15px;
  font-size: 20px;
}
.counter-section i:hover{
  transform: rotate(360deg);
  background-color: #6C7A89;
  color: #fff;
  /* Safari */
  -webkit-transform: rotate(-360deg);
  transition: all ease .6s;
  /* Firefox */
  -moz-transform: rotate(-360deg);

  /* IE */
  -ms-transform: rotate(-360deg);

  /* Opera */
  -o-transform: rotate(-360deg);
}
.h3-tag h3{
  color: #5B3256;
}
/* END OF COUNTER SECTION */
/* CONTACT SECTION */
.contact{
  background-image: url("./components/img/contact-two.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  width: 100%;
}
.contact-section{
  margin-top: 20px !important;
}
/* CONTACT SECTION */
/* NAVBAR SECTION */
.bg-dark{
  opacity: 0.8;
  background: #000 !important;
  color: #fff;
  padding-bottom: 0px;
}
.navbar{
  padding-bottom: 0.7rem !important;
}
.navbar-nav{
  position: relative;
  padding-top: 20px;
}
.navbar a{
  color: rgb(172, 175, 177) !important;
}
.nav-item{
  padding-top: 20px;
}
.nav-item a{
  color: aliceblue !important;
}
.justify-content-end{
  position: absolute;
  margin-top: -40px;
  right: 0;
}
.nav-item a:hover{
  font-weight: bold;
  border-bottom: 1px solid #565387;
  background-color: rgb(93, 63, 106, 0.8);
  border-radius: 0 20px 0 20px;
}
 .social-list .nav-link{
  padding: 5px !important;
  border: 1px solid #5B3256;
  margin-left: 5px;
}
.social-list .nav-link i:hover{
  background-color: #5B3256;
  padding: 7px;
  border-radius: 0 25px 0 25px;
  transition: all ease .6s;
}

/* END OF COUNTER SECTION */
/* END OF COUNTER SECTION */

/* app Slide */
.appSlide{
  margin-top: 0px !important;
  padding-top: 20px !important;
}
#footer-section{
  background-image: url("./components/img/footer.jpg");
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.footer a{
  border: 3px solid #fff;
  margin: 5px;
  border-radius: 10px 0 10px 0;
}
.footer a:hover{
  transform:rotate(360deg);
  -ms-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform: translateY(10px);
  background-color: #5B3256;
  transition: all ease .6s;
}
.address{
  position: relative;
  margin-top: -95px;
  color: #fff;
}
.address p{
  margin: 0;
  padding: 0;
 font-weight:bold;
}
.menu_active {
  font-weight: bold;
  border-bottom: 3px solid #565387;
  background-color: rgb(93, 63, 106, 0.8);
  border-radius: 0 20px 0 20px;
}
.con-info{
  font-size: 14px;
}
.modal-content{
  background-color: rgb(93, 63, 106, 0.8);
}
.card-header{
  padding: 15px 10px;
  text-align: center;
}

h4{
  font-weight: 600 ;
  font-family: 'Prompt', sans-serif;
}
h1{
  font-weight: bold;
}
h2{
  font-weight: 700 ;
  font-family: 'Prompt', sans-serif;
  font-style: italic;
}
.lead{
  font-family: 'Times New Roman', Times, serif;
  font-size: 25px;
}
strong{
  font-weight: 700;
}
.enrollName{
  color: orange;
}
.navbar-toggler{
  background-color: #fff !important;
}

.contact-section input#formPlaintextPassword.form-control {
  background-color: rgb(0, 0, 0, 0.3);
  color: #fff;
  font-weight: bold;
}
.contact-section #exampleForm{
  background-color: rgb(0, 0, 0, 0.3);
  color: #fff;
  font-weight: bold;
}
.contact-section form ::placeholder{
  color: #fff;
  font-weight: bold;
}
.noticeWhite{
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
}

.table-bordered{
  border: 2px solid white !;
}

  @media only screen and (max-width: 991px) {
    .navbar{
      /* padding-bottom: 1rem !important; */
    }
    .domainName{
      position: absolute;
      top: 100px;
      left: -10px;
      font-size: 18px;
      font-weight: bolder;
    }
    /* app Slide */
    .appSlide{
      margin-top: 32px !important;
      padding-top: 5px !important;
    }
    .contact-section {
      margin-top: -2px !important;
  }
    .ml-auto{
      margin-right: auto !important;
    }
    .navbar-nav .nav-link{
      padding: 5px;
    }
    .bg-dark {
      background-color: #000 !important;
      color: #fff;
      opacity: .9;
      padding-bottom: 25px;
    }
    .navbar-nav{
      position: relative;
    }
    .justify-content-end{
      position: absolute;
      top: -15px;
      right: 35%;
    }
    .nav-item{
      padding-top: 0px;
    }
    .navbar-nav .social-list{
      justify-content: flex-start !important;
      margin-left: 80px;
      margin-top: -75px;
      z-index: -555;
    }
  .social-list p{
      padding: 0 !important;
    } 
   
  }
  @media only screen and (max-width: 767px){

    .navbar-nav .first-item{
      margin-top: -42px;
    }
    .footer-body .nav-link{
      margin: 0 10px;
      padding: 0;
    }
    .home-button{
      text-align: center;
    }
  }
  @media only screen and (max-width: 640px){
    #footer-section{
      height: 72vh !important;
    }
  }
  @media only screen and (max-width: 575px){
    .navbar-nav{
      position: relative;
    }
    .justify-content-end{
      position: absolute;
      top: -15px;
      right: 35%;
    }
    .header .head-line h3{
      position: absolute;
      font-size: 20px;
      margin-top: -34px;
    }
    .header .head-line h1{
      position: absolute;
      margin: 0;
      font-size: 16px;
    }
    .explorer-section h2{
      margin-top: 10px;
      font-size: 20px;
    }
    #footer-section{
      height: 70vh !important;
    }
    .navbar-nav .first-item{
      margin-top: 0px;
    }
    .home-button .btn-two{
      text-align: center;
      width: 100%;
    }
    .header{
      text-align: center;
    }
    .header .head-line h3{
      padding-top: 25px;
    }
    .header .head-line h1{
      padding-top: 35px;
    }
    .header .head-line h2{
      margin-top: 45px;
      margin-bottom: -25px;
      padding-top: 70px;
    }
  }
  @media only screen and (max-width: 350px){
    .explorer-section h2{
      margin-top: 10px;
      font-size: 16px;
    }
    .navbar-nav{
      position: relative;
      top: -15%;
    }
    .navbar {
      padding-bottom: 1.5rem !important;
    }
    .justify-content-end{
      position: absolute;
      top: 108%;
      right: 35%;
    }
    .social-list .nav-link{
      border: 1px solid #5B3256;
    }
    .social-list .nav-link i:hover{
      background-color: #5B3256;
      padding: 7px;
      border-radius: 0 25px 0 25px;
      transition: all ease .6s;
    }
  }
  @media only screen and (max-width: 263px){
    .justify-content-end{
      position: absolute;
      top: 108%;
      right: 15%;
    }
  }

  /* ENROLLMENT */

  .enrollment{
    border: 5px solid #000;
    border-radius: 50px;
    padding: 25px;
  }
  /* REGISTRATION FORM */
  .registration-section{
    border: 5px solid #000;
    border-radius: 50px;
    padding: 25px;
    margin-top: 25px;
  }
  /* REGISTRATION FORM */
  /* course-section */
  .energency-section{
    border: 2px solid #fff;
    border-radius: 25px;
    padding: 10px 15px 10px 15px;
    margin-top: 10px;
  }
  /* education-border-section */
  .education-border{
    /* border: 5px solid #000;
    border-radius: 50px;
    padding: 15px 0 15px 0;
    margin-top: 25px; */
  }
  .addminssion-section{
    border: 5px solid #000;
    border-radius: 50px;
    padding: 25px;
    margin-top: 25px;
  }
  .hPPIzc{
    border-radius: 5px !important;
  }
  /* .kDFSwf .required:after {
    content:" *";
    color: red;
  }
  :input:required
  .required:after {
    content:" *";
    color: red;
  } */
  /* course-section */
  /* course-section */
  /* course-section */
  /* course-section */
  /* course-section */
  /* course-section */
  /* course-section */
  /* course-section */
  /*VALIDATION*/
/* .field-validation-error{
    color: red;
}
.input-validation-error {
    color: red;
}
.form-control {
    border: 1px solid red;
} */
input:required:focus {
  border: 2px solid red;
  outline: none;
}
select:required:focus {
  border: 2px solid red;
  outline: none;
}